import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Guest, MhProfile, Reservation, ReservationWorkflow } from '@bhe/types';
import { RxState } from '@rx-angular/state';
import { map, Observable } from 'rxjs';
import { OverviewReservationPressInfosComponentModule } from '../overview-reservation-press-infos/overview-reservation-press-infos.component';
import { OverviewReservationGuestsComponentModule } from '../overview-reservation-guests/overview-reservation-guests.component';
import { OverviewWorkflowComponentModule } from '../overview-workflow/overview-workflow.component';
import {
  OverviewBlockComponentModule,
  OverviewField,
} from '../overview-block/overview-block.component';
import { OverviewGuestComponentModule } from '../overview-guest/overview-guest.component';
import { OverviewEventsService } from './overview-events-service.service';
import { MhProfileRendererComponentModule } from '../../form/mh-profile-renderer/mh-profile-renderer.component';
import { OverviewFieldWrapperComponentModule } from '../overview-field-wrapper/overview-field-wrapper.component';
import { OverviewAccompanyningComponentModule } from '../overview-accompanyning/overview-accompanyning.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { OverviewNewClientComponentModule } from '../overview-new-client/overview-new-client.component';
import { LetModule, PushModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-overview-reservation-infos',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="reservation$; let reservation">
        <ng-container *ngIf="reservation">
          <div class="b-main-people">
            <bhe-res-overview-guest
              [guest]="reservation.mainGuest"
              [mainLabel]="t('reservation.form.main-guest.label')"
            ></bhe-res-overview-guest>
            <ng-container *ngIf="isGuest(reservation.invitedBy)">
              <ng-container *ngrxLet="invitedByGuest(); let guest">
                <bhe-res-overview-guest
                  [guest]="guest"
                  [mainLabel]="t('reservation.form.invited-by.label')"
                ></bhe-res-overview-guest>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isMhProfile(reservation.invitedBy)">
              <ng-container *ngrxLet="invitedByMhProfile(); let mhProfile">
                <bhe-res-overview-field-wrapper
                  [title]="t('reservation.form.invited-by.label')"
                >
                  <bhe-res-mh-profile-renderer
                    [displayMode]="'column'"
                    [mhProfile]="mhProfile"
                  ></bhe-res-mh-profile-renderer>
                </bhe-res-overview-field-wrapper>
              </ng-container>
            </ng-container>
          </div>

          <ng-container
            *ngIf="
              reservation?.field_mh_accompanying_people as accompanyingPeople
            "
          >
            <ng-container *ngIf="accompanyingPeople.length > 0">
              <bhe-res-overview-field-wrapper
                class="b-accompanying-people"
                [title]="t('reservation.form.accompanying-people.label')"
              >
                <bhe-res-overview-accompanyning
                  [mhProfiles]="accompanyingPeople"
                >
                </bhe-res-overview-accompanyning>
              </bhe-res-overview-field-wrapper>
            </ng-container>
          </ng-container>

          <bhe-res-overview-block
            [title]="'Final Date and Guest Count'"
            [fields]="dateBlockFields$ | ngrxPush"
          ></bhe-res-overview-block>

          <bhe-res-overview-block
            [title]="'First Desired Dates'"
            [fields]="firstDesiredDates$ | ngrxPush"
          ></bhe-res-overview-block>

          <bhe-res-overview-block
            [title]="'Second Desired Dates'"
            [fields]="secondDesiredDates$ | ngrxPush"
          ></bhe-res-overview-block>

          <bhe-res-overview-block
            [title]="'reservation.form.visit-duration.block-title'"
            [fields]="visitDuration$ | ngrxPush"
          ></bhe-res-overview-block>

          <bhe-res-overview-block
            [title]="'reservation.form.requests.block-title'"
            [fields]="servicesRequest$ | ngrxPush"
          ></bhe-res-overview-block>

          <bhe-res-overview-workflow
            [workflow]="reservation.workflow"
            [reservation]="reservation"
            (workflowChanged)="workflowChanged.emit($event)"
          >
          </bhe-res-overview-workflow>
          <bhe-res-overview-block
            [fields]="otherBlockFields$ | ngrxPush"
          ></bhe-res-overview-block>
          <ng-container *ngIf="reservation.pressInfos as pressInfos">
            <bhe-res-overview-reservation-press-infos [pressInfos]="pressInfos">
            </bhe-res-overview-reservation-press-infos>
          </ng-container>
          <ng-container
            *ngIf="
              reservation.guestType?.code === 'trade' ||
              reservation.guestType?.code === 'hnwi'
            "
          >
            <bhe-res-overview-new-client
              [reservation]="reservation"
            ></bhe-res-overview-new-client>
          </ng-container>
          <ng-container *ngIf="reservation.guests!.length > 0">
            <h3>Accompanying Guests</h3>
            <bhe-res-overview-reservation-guests
              [guests]="reservation.guests"
            ></bhe-res-overview-reservation-guests>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./overview-reservation-infos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, DatePipe, OverviewEventsService],
})
export class OverviewReservationInfosComponent {
  reservation$ = this.state.select('reservation');

  dateBlockFields$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const { reservationDate, reservationEndDate } = reservation;
        const guestCount = reservation.guests ? reservation.guests.length + 1 : 1;
        return [
          {
            label: 'Date',
            value: this.datePipe.transform(
              reservationDate,
              'MMM d y',
              '+00:00'
            ),
          },
          {
            label: 'End Date',
            value: this.datePipe.transform(
              reservationEndDate,
              'MMM d y',
              '+00:00'
            ),
          },
          {
            label: 'Guest Count',
            value: guestCount,
          },
        ];
      }
      return [];
    })
  );

  otherBlockFields$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const { country, requestor, guestType, language, secondaryLanguage, field_code_budget } =
          reservation;
        return [
          {
            label: 'Country',
            value: country,
            type: 'country',
          },
          {
            label: 'Requestor',
            value: requestor?.displayName,
          },
          {
            label: 'Budget Code',
            value: field_code_budget,
          },
          {
            label: 'Guest Type',
            value: guestType?.name,
          },
          {
            label: 'Main Language',
            value: language,
            type: 'language',
          },
          {
            label: 'Secondary Language',
            value: secondaryLanguage,
            type: 'language',
          },
        ];
      }
      return [];
    })
  );

  firstDesiredDates$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const { field_first_desired_date, field_first_desired_end_date } =
          reservation;
        return [
          {
            label: this.translocoService.translate('Date'),
            value: field_first_desired_date,
          },
          {
            label: this.translocoService.translate('End Date'),
            value: field_first_desired_end_date,
          },
        ];
      }
      return [];
    })
  );

  secondDesiredDates$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const { field_second_desired_date, field_second_desired_end_date } =
          reservation;
        return [
          {
            label: this.translocoService.translate('Date'),
            value: field_second_desired_date,
          },
          {
            label: this.translocoService.translate('End Date'),
            value: field_second_desired_end_date,
          },
        ];
      }
      return [];
    })
  );

  visitDuration$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const {
          field_duration_number_of_days,
          field_duration_number_of_hours,
        } = reservation;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.visit-duration.days'
            ),
            value: field_duration_number_of_days,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.visit-duration.hours'
            ),
            value: field_duration_number_of_hours,
          },
        ];
      }
      return [];
    })
  );

  servicesRequest$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const {
          field_accommodation_requested,
          field_restaurant_requested,
          field_tasting_requested,
          field_vineyards_visit_requested,
        } = reservation;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.requests.accomodation'
            ),
            value: field_accommodation_requested,
            type: 'boolean',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.requests.restaurant'
            ),
            value: field_restaurant_requested,
            type: 'boolean',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.requests.tasting'
            ),
            value: field_tasting_requested,
            type: 'boolean',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.requests.vineyards'
            ),
            value: field_vineyards_visit_requested,
            type: 'boolean',
          },
        ];
      }
      return [];
    })
  );

  @Input()
  set reservation(reservation: Reservation) {
    this.state.set({ reservation });
  }

  @Output()
  confirmApprobation = this.overviewEventsService.confirmApprobationChoice$;

  @Output()
  workflowChanged: EventEmitter<ReservationWorkflow> = new EventEmitter<ReservationWorkflow>();

  constructor(
    private state: RxState<{ reservation: Reservation }>,
    private datePipe: DatePipe,
    private overviewEventsService: OverviewEventsService,
    private translocoService: TranslocoService
  ) {}

  isGuest(profile: any) {
    return profile instanceof Guest;
  }

  isMhProfile(profile: any) {
    return profile instanceof MhProfile;
  }

  invitedByGuest(): Observable<Guest> {
    return this.reservation$.pipe(
      map((reservation) => reservation.invitedBy as Guest)
    );
  }

  invitedByMhProfile(): Observable<MhProfile> {
    return this.reservation$.pipe(
      map((reservation) => reservation.invitedBy as MhProfile)
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    OverviewGuestComponentModule,
    OverviewBlockComponentModule,
    OverviewReservationPressInfosComponentModule,
    OverviewReservationGuestsComponentModule,
    OverviewWorkflowComponentModule,
    MhProfileRendererComponentModule,
    OverviewFieldWrapperComponentModule,
    OverviewAccompanyningComponentModule,
    TranslocoModule,
    OverviewNewClientComponentModule,
    LetModule,
    PushModule
  ],
  declarations: [OverviewReservationInfosComponent],
  exports: [OverviewReservationInfosComponent],
})
export class OverviewReservationInfosComponentModule {}
