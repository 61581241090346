import { Expose, plainToClass, plainToInstance, Transform } from "class-transformer";
import "reflect-metadata";
import { DrupalNode } from "./drupal-node.model";
import { ReservationPart } from "./reservation-part.model";
import { Resource, ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { Guest } from "./guest.model";
import { GuestType } from "./guest-type.model";
import { User } from "./user.model";
import { ReservationWorkflow } from "./reservation-workflow";
import { MhProfile } from "./mh-profile.model";

export class ArticleInfos {
  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_article_before_when;
  })
  @Expose()
  date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_article_before_which;
  })
  @Expose()
  name!: string;
}

export class ReservationPressInfos {
  @Transform(({ obj }) => {
    if (obj?.attributes?.field_press_article_before) {
      return plainToInstance(ArticleInfos, obj, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      });
    }
    return null;
  })
  @Expose()
  articleInfos!: ArticleInfos;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_circulations;
  })
  @Expose()
  circulations!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_itw_types;
  })
  @Expose()
  interviewTypes!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_media_title;
  })
  @Expose()
  mediaTitle!: string;

  @Transform(({ obj }) => {
    const fieldValue: string = obj?.attributes?.field_press_next_pub_date;
    return fieldValue?.length > 0 ? fieldValue : null;
  })
  @Expose()
  nextPublicationDate!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_other_media_partners;
  })
  @Expose()
  otherMediaPartner!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_pubfreq;
  })
  @Expose()
  publicationFrequency!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_target;
  })
  @Expose()
  target!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_website;
  })
  @Expose()
  website!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects;
  })
  @Expose()
  subjectsOfTheVisit!: string[];

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects_so;
  })
  @Expose()
  visitSpecialOccasion!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects_fpv;
  })
  @Expose()
  visitFocusOnVintage!: string;
}

export class Reservation extends DrupalNode {
  static type = "node--reservation";

  @Transform(({ obj }) => {
    const guestType: Resource | undefined =
      obj?.relationships?.field_guest_type?.data?.reference;
    const guestTypeMachineName: string =
      guestType?.attributes?.["machine_name"];
    if (guestTypeMachineName === "press") {
      return plainToInstance(ReservationPressInfos, obj, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      });
    }
    return null;
  })
  @Expose()
  pressInfos!: ReservationPressInfos;

  @Transform(({ obj }) => {
    const reservationParts: Resource[] | undefined =
      obj?.relationships?.field_reservation_parts?.data?.map((ref: any) => {
        return ref.reference;
      });
    if (reservationParts) {
      return reservationParts.map((reservationPart) => {
        return plainToClass(ReservationPart, reservationPart, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false
        });
      });
    }
    return [];
  })
  @Expose()
  reservationParts!: ReservationPart[];

  @Transform(({ obj }) => {
    const guest: Resource | undefined =
      obj?.relationships?.field_main_guest?.data?.reference;
    if (guest) {
      return plainToClass(Guest, guest, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      });
    }
    return null;
  })
  @Expose()
  mainGuest!: Guest | undefined;

  @Transform(({ obj }) => {
    const invitedBy: Resource | undefined =
      obj?.relationships?.field_invited_by?.data?.reference;
    if (invitedBy) {
      if (invitedBy.type === Guest.type) {
        return plainToClass(Guest, invitedBy, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false
        });
      } else if (invitedBy.type === MhProfile.type) {
        return plainToClass(MhProfile, invitedBy, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false
        });
      }
    }
    return null;
  })
  @Expose()
  invitedBy!: Guest | MhProfile | undefined;

  @Transform(({ obj }) => {
    const accompanyingPeople: ResourceIdentifier[] =
      obj?.relationships?.field_mh_accompanying_people?.data;
    if (accompanyingPeople) {
      return accompanyingPeople.map((mhProfile: any) => {
        return plainToInstance(MhProfile, mhProfile.reference, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false
        });
      });
    }
    return null;
  })
  @Expose()
  field_mh_accompanying_people!: MhProfile[];

  @Transform(({ obj }) => {
    return obj?.attributes?.field_reservation_date;
  })
  @Expose()
  reservationDate!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_reservation_end_date;
  })
  @Expose()
  reservationEndDate!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_desired_date;
  })
  @Expose()
  field_first_desired_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_desired_end_date;
  })
  @Expose()
  field_first_desired_end_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_second_desired_date;
  })
  @Expose()
  field_second_desired_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_second_desired_end_date;
  })
  @Expose()
  field_second_desired_end_date!: string;

  @Transform(({ obj }) => {
    console.log('field_code_budget', {obj});
    return obj?.attributes?.field_code_budget;
  })
  @Expose()
  field_code_budget!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_reservation_id;
  })
  @Expose()
  bheId!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_country;
  })
  @Expose()
  country!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_workflow_reservation;
  })
  @Expose()
  workflow!: ReservationWorkflow;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_guest_total;
  })
  @Expose()
  guestCount!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_main_language;
  })
  @Expose()
  language!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_secondary_language;
  })
  @Expose()
  secondaryLanguage!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_duration_number_of_days;
  })
  @Expose()
  field_duration_number_of_days!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_duration_number_of_hours;
  })
  @Expose()
  field_duration_number_of_hours!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_accommodation_requested;
  })
  @Expose()
  field_accommodation_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_restaurant_requested;
  })
  @Expose()
  field_restaurant_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_tasting_requested;
  })
  @Expose()
  field_tasting_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_vineyards_visit_requested;
  })
  @Expose()
  field_vineyards_visit_requested!: string;

  /****** NEW CLIENT *******/
  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_date_of_last;
  })
  @Expose()
  field_new_client_date_of_last!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_met_ambassador;
  })
  @Expose()
  field_new_client_met_ambassador!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_champagne;
  })
  @Expose()
  field_new_client_champagne!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_ordered_months;
  })
  @Expose()
  field_new_client_ordered_months!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_house_event;
  })
  @Expose()
  field_new_client_house_event!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_establishment;
  })
  @Expose()
  field_new_client_establishment!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_private_member;
  })
  @Expose()
  field_new_client_private_member!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_property;
  })
  @Expose()
  field_new_client_property!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_quality_ordered;
  })
  @Expose()
  field_new_client_quality_ordered!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_which_event;
  })
  @Expose()
  field_new_client_which_event!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_new_client_which_houses?.data;
  })
  @Expose()
  field_new_client_which_houses!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    const guestType: Resource | undefined =
      obj?.relationships?.field_guest_type?.data?.reference;
    if (guestType) {
      return plainToClass(GuestType, guestType, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      });
    }
    return null;
  })
  @Expose()
  guestType!: GuestType;

  @Transform(({ obj }) => {
    const user: Resource | undefined = obj?.relationships?.uid?.data?.reference;
    if (user) {
      return plainToClass(User, user, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      });
    }
    return null;
  })
  @Expose()
  requestor!: User;

  @Transform(({ obj }) => {
    const reservationParts: Resource[] | undefined =
      obj?.relationships?.field_reservation_guests?.data?.map((ref: any) => {
        return ref.reference;
      });
    if (reservationParts) {
      return reservationParts.map((guest) => {
        return plainToClass(Guest, guest, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false
        });
      });
    }
    return [];
  })
  @Expose()
  guests!: Guest[];

  /***** GraphQL fields *****/

  @Transform(({ obj }) => {
    return obj?.field_main_guest_full_name;
  })
  @Expose()
  searchMainGuest!: string;

  @Transform(({ obj }) => {
    return obj?.field_guest_type_name;
  })
  @Expose()
  searchGuestType!: string;

  @Transform(({ obj }) => {
    return obj?.field_country;
  })
  @Expose()
  searchCountry!: string;

  @Transform(({ obj }) => {
    const startDate = obj?.field_first_desired_date;
    const endDate = obj?.field_first_desired_end_date;
    if (startDate && endDate) {
      return { from: startDate*1000, to: endDate*1000 };
    }
    return undefined;
  })
  @Expose()
  searchDesiredPeriod: { from: number, to: number } | undefined;

  /* Manage Guest reference and AD Profile reference */
  @Transform(({ obj }) => {
    const guestName: string = obj?.field_invited_by_full_name;
    const adProfileFirstName = obj?.field_invited_by_first_name;
    const adProfileLastName = obj?.field_invited_by_last_name;
    let adProfileName;
    if (adProfileFirstName && adProfileLastName) {
      adProfileName = `${adProfileFirstName} ${adProfileLastName}`;
    }
    return guestName || adProfileName;
  })
  @Expose()
  searchInvitedBy!: string;

  @Transform(({ obj }) => {
    return obj?.field_reservation_date * 1000;
  })
  @Expose()
  searchReservationDate!: number;

  @Transform(({ obj }) => {
    return obj?.field_reservation_id;
  })
  @Expose()
  searchBheId!: number;

  @Transform(({ obj }) => {
    return obj?.field_workflow_reservation;
  })
  @Expose()
  searchWorkflow!: ReservationWorkflow;

  @Transform(({ obj }) => {
    return obj?.field_user_full_name && obj?.field_user_full_name !== " "
      ? obj?.field_user_full_name
      : obj?.name;
  })
  @Expose()
  searchRequestor!: string;

  @Transform(({ obj }) => {
    return obj?.field_brands_codes_array;
  })
  @Expose()
  searchBrands!: string[];

  @Transform(({ obj }) => {
    return obj?.created * 1000;
  })
  @Expose()
  searchCreated!: number;

  @Transform(({ obj }) => {
    return obj?.changed * 1000;
  })
  @Expose()
  searchChanged!: number;

  @Transform(({ obj }) => {
    return obj?.uuid;
  })
  @Expose()
  searchUuid!: string;
}
