import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { NodeMeta } from '../drupal-node.model';

export class ReservationForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_country;
  })
  @Expose()
  field_country!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_desired_date;
  })
  @Expose()
  field_first_desired_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_desired_end_date;
  })
  @Expose()
  field_first_desired_end_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_second_desired_date;
  })
  @Expose()
  field_second_desired_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_second_desired_end_date;
  })
  @Expose()
  field_second_desired_end_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_reservation_date;
  })
  @Expose()
  field_reservation_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_reservation_end_date;
  })
  @Expose()
  field_reservation_end_date!: string;

  @Transform(({ obj }) => {
    console.log('field_code_budget', {obj});
    return obj?.attributes?.field_code_budget;
  })
  @Expose()
  field_code_budget!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_main_language;
  })
  @Expose()
  field_main_language!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_secondary_language;
  })
  @Expose()
  field_secondary_language!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_duration_number_of_days;
  })
  @Expose()
  field_duration_number_of_days!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_duration_number_of_hours;
  })
  @Expose()
  field_duration_number_of_hours!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_accommodation_requested;
  })
  @Expose()
  field_accommodation_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_restaurant_requested;
  })
  @Expose()
  field_restaurant_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_tasting_requested;
  })
  @Expose()
  field_tasting_requested!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_vineyards_visit_requested;
  })
  @Expose()
  field_vineyards_visit_requested!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_type?.data;
  })
  @Expose()
  field_guest_type!: ResourceIdentifier;

  @Transform(({ obj }) => {
    const rawRef: any = obj?.relationships?.field_main_guest?.data;
    if (rawRef) {
      const { meta, ...ref } = rawRef;
      return ref;
    }
    return null;
  })
  @Expose()
  field_main_guest!: ResourceIdentifier;

  @Transform(({ obj }) => {
    const rawRef: any = obj?.relationships?.field_invited_by?.data;
    if (rawRef) {
      const { meta, ...ref } = rawRef;
      return ref;
    }
    return null;
  })
  @Expose()
  field_invited_by!: ResourceIdentifier;

  @Transform(({ obj }) => {
    const rawRef: any = obj?.relationships?.uid?.data;
    if (rawRef) {
      const { meta, ...ref } = rawRef;
      return ref;
    }
    return null;
  })
  @Expose()
  uid!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_mh_accompanying_people?.data?.map(
      (d: any) => {
        const { id, type } = d;
        return { id, type };
      }
    );
  })
  @Expose()
  field_mh_accompanying_people!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_reservation_guests?.data?.map((d: any) => {
      const { id, type } = d;
      return { id, type };
    });
  })
  @Expose()
  field_reservation_guests!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_reservation_parts?.data?.map((d: any) => {
      const { id, type } = d;
      return { id, type };
    });
  })
  @Expose()
  field_reservation_parts!: ResourceIdentifier[];

  /******* PRESS *******/
  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_article_before;
  })
  @Expose({ groups: ['press'] })
  field_press_article_before!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_article_before_when;
  })
  @Expose({ groups: ['press'] })
  field_press_article_before_when!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_article_before_which;
  })
  @Expose({ groups: ['press'] })
  field_press_article_before_which!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_media_title;
  })
  @Expose({ groups: ['press'] })
  field_press_media_title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_next_pub_date;
  })
  @Expose({ groups: ['press'] })
  field_press_next_pub_date!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_circulations;
  })
  @Expose({ groups: ['press'] })
  field_press_circulations!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_itw_types;
  })
  @Expose({ groups: ['press'] })
  field_press_itw_types!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_pubfreq;
  })
  @Expose({ groups: ['press'] })
  field_press_pubfreq!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_target;
  })
  @Expose({ groups: ['press'] })
  field_press_target!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_website;
  })
  @Expose({ groups: ['press'] })
  field_press_website!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_press_other_media_partners;
  })
  @Expose({ groups: ['press'] })
  field_press_other_media_partners!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects;
  })
  @Expose({ groups: ['press'] })
  field_visit_subjects!: string[];

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects_so;
  })
  @Expose({ groups: ['press'] })
  field_visit_subjects_so!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_subjects_fpv;
  })
  @Expose({ groups: ['press'] })
  field_visit_subjects_fpv!: string;

  /******* NEW CLIENT *******/

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_property;
  })
  @Expose()
  field_new_client_property!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_date_of_last;
  })
  @Expose()
  field_new_client_date_of_last!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_met_ambassador;
  })
  @Expose()
  field_new_client_met_ambassador!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_champagne;
  })
  @Expose()
  field_new_client_champagne!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_ordered_months;
  })
  @Expose()
  field_new_client_ordered_months!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_house_event;
  })
  @Expose()
  field_new_client_house_event!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_establishment;
  })
  @Expose()
  field_new_client_establishment!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_private_member;
  })
  @Expose()
  field_new_client_private_member!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_quality_ordered;
  })
  @Expose()
  field_new_client_quality_ordered!: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_new_client_which_event;
  })
  @Expose()
  field_new_client_which_event!: boolean;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_new_client_which_houses?.data?.map(
      (d: any) => {
        const { id, type } = d;
        return { id, type };
      }
    );
  })
  @Expose()
  field_new_client_which_houses!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.meta;
  })
  @Expose()
  meta!: NodeMeta;
}
