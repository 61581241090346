import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgModule,
  OnDestroy,
  Optional,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { BRANDS } from "@bhe/vocabularies-data";
import { distinctUntilChanged, map, Observable, of, shareReplay, startWith, take, withLatestFrom, combineLatest } from "rxjs";
import { Brand, ReservationPart } from "@bhe/types";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ResourceIdentifier, uuid } from "@madeinlune/ngx-json-api";
import { FieldArrayType } from "@ngx-formly/core";
import { ReservationFormEntitiesService } from "@bhe/reservation-data-access";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BheIconComponentModule } from "@bhe/ui";
import { LetModule } from "@ngrx/component";
import { NEW_RESERVATION_FORM_GUEST_TYPE_UUID } from "../../new-reservation/new-reservation.component";
import { MatInputModule } from "@angular/material/input";
import { BOTTLES_SOLD_FORM } from "@bhe/shell-state";
import { MatSelectModule } from "@angular/material/select";
import { MatQuillModule, NewReservationFormService } from "@bhe/reservation-ui";
import { GuestExperiencesComponentModule } from "../guest-experiences/guest-experiences.component";
import { NewGuestExperiencesComponent } from "../new-guest-experiences/new-guest-experiences.component";
import { QuillModule } from "ngx-quill";

const guestTypeGTR = "38a0e216-be2b-49c5-ae56-b45e6f20cf35";
const guestTypeTrade = "464b5e1e-72a0-4bca-b4e0-abc01f17e547";
const guestMhPrivate = "3f7a60d1-86a3-45f2-b70e-804156958a14";

@UntilDestroy()
@Component({
  selector: "bhe-brand-selection",
  template: `
    <ng-container *ngIf="isNewClientForm">
      <ng-container *ngrxLet="brands$; let brands">
        <ng-container *ngIf="formReady">
          <form [formGroup]="brandForm">
            <ng-template ngFor [ngForOf]="brands" let-brand>
              <ng-container *ngIf="brand">
                <mat-checkbox [formControlName]="brand.id">
                  <div class="check-box-content">
                    <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
                    <span [innerHTML]="brand.name"></span>
                  </div>
                </mat-checkbox>
              </ng-container>
            </ng-template>
          </form>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isNewClientForm">
      <ng-container *ngrxLet="brands$; let brands">
        <ng-container *ngIf="formReady">
          <form [formGroup]="brandForm">
            <ng-template ngFor [ngForOf]="brands" let-brand>
              <ng-container *ngIf="brand">
                <div [formGroupName]="brand.id" class="brand-form-group">
                  <mat-checkbox #cb formControlName="selected">
                    <div class="check-box-content">
                      <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
                      <span [innerHTML]="brand.name"></span>
                    </div>
                  </mat-checkbox>
                  <ng-container *ngIf="showBottlesSoldFormItems$|async">
                    <mat-form-field appearance="fill" [class.hide]="!cb.checked">
                      <mat-label>Bottles Sold</mat-label>
                      <mat-select formControlName="field_bottles_sold">
                        <mat-option *ngFor="let bottlesSold of bottlesSoldForm$|async" [value]="bottlesSold.value">
                          {{ bottlesSold.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!--<mat-form-field appearance="fill" [class.hide]="!cb.checked">
                      <mat-label>Bottles Sold</mat-label>
                      <input matInput formControlName="field_bottles_sold">
                    </mat-form-field>-->
                  </ng-container>
                  <ng-container *ngIf="cb.checked">
                    <h3>Guest Experience</h3>
                    <bhe-new-guest-experiences [brandIdentifier]="{id: brand.id, type: 'taxonomy_term--brand'}"
                                               (guestExperienceSelected)="onGuestExperienceSelected($event, brand.id)"></bhe-new-guest-experiences>
                    <h3 [innerHTML]="'Objectives of the visit *'"></h3>
                    <quill-editor formControlName="field_visit_theme">
                      <div quill-editor-toolbar>
                      <span class="ql-formats">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'Italic'"></button>
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-clean"></button>
                      </span>
                      </div>
                    </quill-editor>
                  </ng-container>
                </div>
              </ng-container>
            </ng-template>
          </form>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./brand-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandSelectionComponent
  extends FieldArrayType
  implements AfterViewInit, OnDestroy {

  brandForm: UntypedFormGroup = new UntypedFormGroup({}, { updateOn: "change" });

  showBottlesSoldFormItems$ = of(false);

  formReady = false;

  // Observable to track errors reactively
  formErrors$ = this.brandForm.statusChanges.pipe(
    startWith(this.brandForm.errors),
    map(() => this.getAllErrors(this.brandForm))
  );

// Recursive function to get all errors
  getAllErrors(form: FormGroup, path = ""): Record<string, any> {
    const errors: Record<string, any> = {};
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      const controlPath = path ? `${path}.${key}` : key;

      if (control instanceof FormGroup) {
        // Recursively get errors from nested controls
        Object.assign(errors, this.getAllErrors(control, controlPath));
      } else if (control?.errors) {
        // Store errors with full path
        errors[controlPath] = control.errors;
      }
    });

    return errors;
  }

  constructor(
    @Inject(BRANDS) public brands$: Observable<Brand[]>,
    @Inject(BOTTLES_SOLD_FORM) public bottlesSoldForm$: Observable<{ value: string, label: string }[]>,
    @Optional() @Inject(NEW_RESERVATION_FORM_GUEST_TYPE_UUID) private guestTypeUuid$: Observable<string | undefined>,
    @Optional() private reservationFormService: ReservationFormEntitiesService,
    private newReservationFormService: NewReservationFormService,
    @Inject(ElementRef) private el: ElementRef<HTMLElement>,
    @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef
  ) {
    super();

    if (guestTypeUuid$) {
      this.showBottlesSoldFormItems$ = combineLatest([this.guestTypeUuid$.pipe(distinctUntilChanged()), this.brands$]).pipe(
        map(([uuid, brands]) => {
            if (uuid) {
              const showBottlesSold = [guestTypeGTR, guestTypeTrade, guestMhPrivate].indexOf(uuid) > -1;
              let hasReservationParts = false;
              for (const { id } of brands) {
                const selected = this.brandForm.get(id)?.get("selected");
                if (selected) {
                  hasReservationParts = true;
                }
                const bottleSoldControl = this.brandForm.get(id)?.get("field_bottles_sold");
                if (bottleSoldControl) {
                  if (showBottlesSold && selected) {
                    //bottleSoldControl.addValidators(Validators.required);
                    this.newReservationFormService.brandFormsValid = hasReservationParts && !!bottleSoldControl.value;
                  } else {
                    //bottleSoldControl.removeValidators(Validators.required);
                    bottleSoldControl.setValue("");
                    this.newReservationFormService.brandFormsValid = hasReservationParts && this.brandForm.valid;
                  }
                }
              }
              console.log("showBottlesSoldFormItems$", { hasReservationParts }, "this.brandForm.valid", this.brandForm.valid);
              return showBottlesSold;
            }
            return false;
          }
        ),
        shareReplay(1)
      );
    }

    /*this.formErrors$.subscribe(errors => {
      console.log("BrandSelectionComponent", { errors });
    });*/

    this.brandForm.valueChanges
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.showBottlesSoldFormItems$),
        map(([value, showBottlesSoldFormItems]) => {
          console.log({ value });
          if(this.isNewClientForm){
            const reservationPartsFields: {
              field_brand: ResourceIdentifier,
              field_bottles_sold?: string,
              field_guest_experience?: ResourceIdentifier,
              field_visit_theme?: string
            }[] = [];
            Object.keys(value).forEach((id) => {
              if (value[id]) {
                reservationPartsFields.push({
                  field_brand: { id, type: Brand.type }
                });
              }
            });
            return reservationPartsFields;
          }else{
            const reservationPartsFields: {
              field_brand: ResourceIdentifier,
              field_bottles_sold: string,
              field_guest_experience: ResourceIdentifier,
              field_visit_theme: string
            }[] = [];
            Object.keys(value).forEach((id) => {
              const brandForm = this.brandForm.get(id);
              if (value[id].selected) {
                reservationPartsFields.push({
                  field_brand: { id, type: Brand.type },
                  field_bottles_sold: value[id].field_bottles_sold,
                  field_guest_experience: value[id].field_guest_experience,
                  field_visit_theme: value[id].field_visit_theme
                });
                if (brandForm) {
                  brandForm.get("field_guest_experience")?.addValidators(Validators.required);
                  brandForm.get("field_visit_theme")?.addValidators(Validators.required);
                  if (showBottlesSoldFormItems) {
                    brandForm.get("field_bottles_sold")?.addValidators(Validators.required);
                  }
                }
              } else {
                if (brandForm) {
                  brandForm.get("field_guest_experience")?.removeValidators(Validators.required);
                  brandForm.get("field_visit_theme")?.removeValidators(Validators.required);
                  brandForm.get("field_bottles_sold")?.removeValidators(Validators.required);
                }
              }
            });
            return reservationPartsFields;
          }
        })
      )
      .subscribe((reservationPartsFields) => {
        // used in New Client (this.isReservationPart===false) and new reservation form (this.isReservationPart===true)
        if (this.isReservationPart) {
          if (this.formControl.controls) {
            this.formControl.controls.forEach((control) => {
              this.reservationFormService.removeEntity(control.value.id);
            });
          }
          this.formControl.clear({ emitEvent: false });
          console.log({ reservationPartsFields });
          const hasReservationParts = reservationPartsFields.length > 0;
          reservationPartsFields.forEach((rp) => {
            const reservationPart = {
              id: uuid(),
              type: ReservationPart.type,
              field_brand: rp.field_brand,
              field_bottles_sold: rp.field_bottles_sold,
              field_guest_experience: rp.field_guest_experience,
              field_visit_theme: rp.field_visit_theme
            };
            /*if (!rp.field_bottles_sold || !rp.field_guest_experience || !rp.field_visit_theme) {
              brandFormValid = false;
            }*/
            const { id, type } = reservationPart;
            console.log({ reservationPart });
            this.reservationFormService.addEntity(reservationPart, "NEW");
            this.formControl.push(new UntypedFormControl({ id, type }), {
              emitEvent: false
            });
          });
          console.log({ hasReservationParts }, "this.brandForm.valid", this.brandForm.valid);
          this.newReservationFormService.brandFormsValid = hasReservationParts && this.brandForm.valid;
          /*brandRefs.forEach((brandRef) => {
            this.formControl.push(new FormControl(brandRef));
          });*/
        } else {
          console.log({reservationPartsFields});
          this.formControl.clear({ emitEvent: false });
          reservationPartsFields.forEach((rp) => {
            this.formControl.push(new UntypedFormControl(rp.field_brand), {
              emitEvent: false
            });
          });
        }
        this.formControl.updateValueAndValidity({
          emitEvent: true,
          onlySelf: false
        });
      });
  }

  get isReservationPart(): boolean {
    return this.field?.key === 'field_reservation_parts';
  }

  get isNewClientForm(): boolean {
    return this.field?.key === "field_new_client_which_houses";
  }

  ngAfterViewInit(): void {
    console.log("BrandSelectionComponent this.field", this.field);
    console.log("BrandSelectionComponent this.formControl", this.formControl);
    console.log("BrandSelectionComponent this.el.nativeElement.parentElement", this.el.nativeElement.parentElement);
    console.log("BrandSelectionComponent this.el.nativeElement.parentElement?.parentElement", this.el.nativeElement.parentElement?.parentElement);
    console.log("BrandSelectionComponent this.el.nativeElement.parentElement?.parentElement?.parentElement", this.el.nativeElement.parentElement?.parentElement?.parentElement);

    const formControlValue: { type: string, id: string }[] = this.formControl.value ?? [];

    this.brands$.pipe(take(1)).subscribe((brands) => {
      brands.forEach((brand) => {
        if (this.isNewClientForm) {
          console.log({ formControlValue, brand });
          const selected = !!formControlValue.find((el) => el.id === brand.id);
          this.brandForm.addControl(brand.id, new UntypedFormControl(selected));
        } else {
          this.brandForm.addControl(brand.id, new FormGroup({
            selected: new FormControl(false),
            field_bottles_sold: new FormControl("", []),
            field_guest_experience: new FormControl("", []),
            field_visit_theme: new FormControl("", [])
          }));
          this.formControl.value.forEach((value: ResourceIdentifier) => {
            this.brandForm.get(value.id)?.setValue({ selected: true });
          });
        }
      });
      this.brandForm.updateValueAndValidity({
        emitEvent: true,
        onlySelf: false
      });
      this.formReady = true;
      this.cdr.detectChanges();
      console.log("this.brandForm", this.brandForm, "this.formReady", this.formReady);
    });

    /*if (this.formControl && !this.isNewClientForm) {
      if (this.formControl.value) {
        this.formControl.value.forEach((value: ResourceIdentifier) => {
          this.brandForm.get(value.id)?.setValue({ selected: true });
        });
        console.log("this.brandForm", this.brandForm);
        this.brandForm.updateValueAndValidity({
          emitEvent: true,
          onlySelf: false
        });
      }
    }*/
  }

  onGuestExperienceSelected(guestExperienceRef: ResourceIdentifier, brandId: string) {
    const field_guest_experience = this.brandForm.get(brandId)?.get("field_guest_experience");
    console.log({ field_guest_experience });
    if (field_guest_experience) {
      field_guest_experience.setValue(guestExperienceRef);
    }
  }

  ngOnDestroy(): void {
    console.log("BrandSelectionComponent ngOnDestroy");
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    BheIconComponentModule,
    LetModule,
    MatInputModule,
    MatSelectModule,
    GuestExperiencesComponentModule,
    NewGuestExperiencesComponent,
    MatQuillModule,
    QuillModule
  ],
  declarations: [BrandSelectionComponent],
  exports: [BrandSelectionComponent]
})
export class BrandSelectionComponentModule {
}
