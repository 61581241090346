import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Injectable,
  NgModule,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@ngneat/transloco';
import { BheButtonComponentModule } from '@bhe/ui';
import { MatButtonModule } from '@angular/material/button';
import { NewReservationFormService } from "../../new-reservation-form.service";

@Component({
  selector: 'bhe-res-stepper',
  template: `
    <ng-container *transloco="let t">
      <mat-stepper #stepper linear="true">
        <mat-step
          *ngFor="
            let step of field.fieldGroup;
            let index = index;
            let last = last
          "
        >
          <ng-template matStepLabel>{{
            step.templateOptions?.label
          }}</ng-template>

          <div class="b-fields">
            <ng-container
              *ngIf="step.templateOptions?.description as description"
            >
              <ng-container
                *ngIf="step.templateOptions?.['userFirstName'] as userFirstName"
              >
                <div
                  class="description"
                  [innerHTML]="t(description, { user: userFirstName })"
                ></div>
              </ng-container>
            </ng-container>

            <formly-field [field]="step"></formly-field>
          </div>

          <div class="actions">
            <bhe-ui-bhe-button
              *ngIf="index !== 0"
              (click)="stepper.previous()"
              variant="primary"
            >
              <span [innerHTML]="t('actions.previous')"></span>
            </bhe-ui-bhe-button>

            <bhe-ui-bhe-button
              *ngIf="!last"
              (click)="stepper.next()"
              variant="accent"
              [disabled]="!isValid(step)"
            >
              <span [innerHTML]="t('actions.next')"></span>
            </bhe-ui-bhe-button>

            <bhe-ui-bhe-button
              *ngIf="last"
              variant="accent"
              type="submit"
              [disabled]="!form.valid || !newReservationFormService.brandFormsValid"
            >
              <span [innerHTML]="t('actions.create-reservation')"></span>
            </bhe-ui-bhe-button>
          </div>
        </mat-step>
      </mat-stepper>
    </ng-container>
  `,
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {

  @ViewChild(MatStepper) stepper!: MatStepper;


  constructor(
    public newReservationFormService: NewReservationFormService
  ) {
    super();
    this.newReservationFormService.brandFormsValid = false;
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
  }

  isValid(field: FormlyFieldConfig): boolean {
    //we need it for the Main Guest Form in New Reservation, because the Form Guest is external
    let validFromExternal: boolean | undefined = undefined;
    if (field.props?.['valid'] !== undefined) {
      validFromExternal = field.props['valid'];
    }

    if (validFromExternal !== undefined) {
      if (field.key && field.formControl && validFromExternal) {
        return field.formControl.valid;
      }
    } else {
      if (field.key && field.formControl) {
        return field.formControl.valid;
      }
    }

    if (validFromExternal !== undefined) {
      return field.fieldGroup
        ? field.fieldGroup.every((f) => this.isValid(f) && validFromExternal)
        : true;
    } else {
      return field.fieldGroup
        ? field.fieldGroup.every((f) => this.isValid(f))
        : true;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    MatStepperModule,
    TranslocoModule,
    BheButtonComponentModule,
    MatButtonModule,
  ],
  declarations: [StepperComponent],
  exports: [StepperComponent],
})
export class StepperComponentModule {}
