import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BheButtonComponentModule } from '../buttons/bhe-button/bhe-button.component';
import { BheDialogComponent } from '../bhe-dialog/bhe-dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogData } from '@bhe/types';
import { isJsonApiError, jsonApiErrors } from '@bhe/utils';

@Component({
  selector: 'bhe-ui-error',
  template: `
    <ng-container *transloco="let t">
      <header>
        <h2>Oops</h2>
        <small [innerHTML]="code || data.code"></small>
      </header>
      <p [innerHTML]="message || data.message"></p>
      <small [innerHTML]="rawErrorMessage"></small>
      <bhe-ui-bhe-button variant="accent" (click)="onOkClick()">
        <span [innerHTML]="t('actions.ok')"></span>
      </bhe-ui-bhe-button>
    </ng-container>
  `,
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnChanges {
  @Output()
  ok: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  code!: number;

  @Input()
  message!: string;

  @Input()
  error!: any;

  rawErrorMessage!: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData,
    @Optional() public dialogRef: MatDialogRef<BheDialogComponent>
  ) {
    if (this.data?.error) {
      this.error = this.data?.error;
      this.#processError();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.#processError();
  }

  onOkClick() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.ok.emit();
    }
  }

  #processError(): void {
    const error: any = this.error.error || this.error;
    if (isJsonApiError(error)) {
      const errors = jsonApiErrors(error);
      if (errors) {
        this.rawErrorMessage = '';
        errors.forEach((err: { detail: string }) => {
          this.rawErrorMessage += err.detail;
        });
      }
    } else {
      try{
        this.rawErrorMessage = JSON.stringify(error);
      }catch(err) {
        this.rawErrorMessage = error;
      }
    }
  }
}

@NgModule({
  imports: [CommonModule, BheButtonComponentModule, TranslocoModule],
  declarations: [ErrorComponent],
  exports: [ErrorComponent],
})
export class ErrorComponentModule {}
