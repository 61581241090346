import {ChangeDetectorRef, Inject, Injectable} from '@angular/core';
import {RxState, selectSlice} from '@rx-angular/state';
import {ResourceIdentifier} from '@madeinlune/ngx-json-api';
import {
  auditTime,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {Reservation, ReservationForm} from '@bhe/types';
import {GUEST_TYPE_PRESS_UUID, GUEST_TYPE_PRIVATE_CLIENT_UUID, GUEST_TYPE_TRADE_UUID,} from '@bhe/vocabularies-data';
import {SUBJECTS_OF_THE_VISIT_FORM} from '@bhe/shell-state';
import {UntypedFormGroup} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {ROUTE_RESERVATION_ID} from '@bhe/router';
import {
  FormEntity,
  FormEntityType,
  loadReservation,
  ReservationFormEntitiesService,
  ReservationService,
  USER_HAS_REQUESTOR_ROLE,
} from '@bhe/reservation-data-access';
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import {isEqual} from '@madeinlune/ngx-operators';
import {HttpErrorResponse} from '@angular/common/http';
import {DateTime} from 'luxon';
import {MatDialog} from '@angular/material/dialog';
import {
  accompanyingPeopleField, budgetBlock,
  countryBlock,
  guestTypeField,
  invitedByField,
  languagesBlock,
  mainGuestField,
  newClientBlock,
  pressBlock,
  reservationDateField,
  reservationGuestsField,
  reservationPartsField,
  servicesRequestsBlock,
  visitDurationBlock,
  wishedDatesBlock
} from "../../reservation.fields";
import {AppCursorService, getDirtyValues} from '@bhe/utils';
import {Store} from '@ngrx/store';

@Injectable()
export class ReservationFormService extends RxState<{
  guestType: ResourceIdentifier;
  reservationParts: ResourceIdentifier[];
  formValue: ReservationForm;
  saving: boolean;
  dirtyFieldsBeforeSave: string[];
}> {
  readonly guestType$: Observable<ResourceIdentifier> =
    this.select('guestType');
  readonly reservationParts$: Observable<ResourceIdentifier[]> =
    this.select('reservationParts');
  readonly saving$: Observable<boolean> = this.select('saving');

  form = new UntypedFormGroup({}, {updateOn: 'blur'});
  formValid$: Observable<boolean> = this.form.valueChanges.pipe(
    startWith(false),
    map((value) => this.form.valid)
  );
  options: FormlyFormOptions = {
    formState: {},
  };

  #refresh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly reservationFormModel$ = this.#refresh$.pipe(
    switchMap(() => {
      return this.routeReservationId$.pipe(
        distinctUntilChanged(),
        switchMap((reservationId) => {
          return this.reservationService.loadReservationFormModel(
            reservationId
          );
        }),
        tap((result: { [type: string]: any[] }) => {
          console.log('reservationFormModel$', {result});
          // this.set({ reservationParts: result[ReservationPart.type] });
          if (result) {
            Object.keys(result).forEach((key) => {
              const formModels: any[] = result[key];
              formModels.forEach((formModel) => {
                this.reservationFormEntitiesService.addEntity(
                  formModel,
                  'IN_SYNC'
                );
              });
            });
          }
        }),
        map((result: { [type: string]: any[] }) => {
          const dirtyProperties: string[] = getDirtyValues(this.form, []);
          const dirtyFormValue: any = {};
          if (dirtyProperties.length > 0) {
            Object.keys(this.form.value).forEach((key) => {
              if (dirtyProperties.indexOf(key) > -1) {
                dirtyFormValue[key] = this.form.value[key];
              }
            });
          }
          if (result[Reservation.type]?.length > 0) {
            return {
              ...result[Reservation.type][0],
              ...dirtyFormValue,
            };
          }
          return null;
        }),
        tap((reservationModel: ReservationForm) => {
          this.set({
            guestType: reservationModel.field_guest_type,
            reservationParts: reservationModel.field_reservation_parts,
          });
        })
      );
    })
  );

  readonly fields$ = combineLatest([
    this.guestTypePressUuid$,
    this.subjectsOfTheVisitForm$,
    this.guestTypePrivateClientUuid$,
    this.guestTypeTradeUuid$,
    this.hasRequestorRole$,
  ]).pipe(
    filter(
      ([
         guestTypePressUuid,
         subjectsOfTheVisitForm,
         guestTypePrivateClientUuid,
         guestTypeTradeUuid,
         hasRequestorRole,
       ]) =>
        !!guestTypePressUuid &&
        !!subjectsOfTheVisitForm &&
        !!guestTypePrivateClientUuid &&
        !!guestTypeTradeUuid
    ),
    take(1),
    map(
      ([
         guestTypePressUuid,
         subjectsOfTheVisitForm,
         guestTypePrivateClientUuid,
         guestTypeTradeUuid,
         hasRequestorRole,
       ]: [
        string,
        { value: string; label: string }[],
        string,
        string,
        boolean
      ]) => {
        const fields: FormlyFieldConfig[] = [
          {
            key: 'id',
            className: 'd-none',
          },
          {
            key: 'type',
            className: 'd-none',
          },
          {
            type: 'tabs',
            fieldGroup: [
              {
                id: 'visit',
                templateOptions: {label: 'Visit'},
                className: 'visit',
                fieldGroupClassName: 'visit-field-group',
                fieldGroup: [
                  {
                    ...wishedDatesBlock(this.translocoService),
                    className: 'wished-dates',
                  },
                  ...(hasRequestorRole
                    ? []
                    : [
                      {
                        ...reservationDateField(this.translocoService),
                        className: 'reservation-date',
                      },
                    ]),
                  ...(hasRequestorRole
                    ? []
                    : [
                      {
                        ...visitDurationBlock(this.translocoService),
                        className: 'visit-duration',
                      },
                    ]),
                  {
                    ...budgetBlock(this.translocoService),
                    className: 'budget',
                  },
                  {
                    ...guestTypeField(this.translocoService),
                    className: 'guest-type',
                  },
                  {
                    ...mainGuestField(this.translocoService),
                    className: 'main-guest',
                  },
                  {
                    ...invitedByField(this.translocoService),
                    className: 'invited-by',
                  },
                  {
                    ...countryBlock(this.translocoService),
                    className: 'country',
                  },
                  {
                    ...languagesBlock(this.translocoService),
                    className: 'languages',
                  },
                  {
                    ...servicesRequestsBlock(this.translocoService),
                    className: 'services-request',
                  },
                  {
                    ...accompanyingPeopleField(this.translocoService),
                    className: 'accompanying-people',
                  },
                ],
              },
              {
                id: 'client',
                templateOptions: {
                  label: this.translocoService.translate(
                    'reservation.tabs.client.label'
                  ),
                  description: this.translocoService.translate(
                    'reservation.tabs.client.description'
                  ),
                },
                hideExpression: (model: any) => {
                  if (model.field_guest_type?.id) {
                    return (
                      model.field_guest_type?.id !== guestTypeTradeUuid &&
                      model.field_guest_type?.id !== guestTypePrivateClientUuid
                    );
                  }
                  return true;
                },
                fieldGroup: [
                  {
                    ...newClientBlock(this.translocoService),
                    fieldGroupClassName: 'client-field-group',
                  },
                ],
              },
              {
                id: 'press',
                templateOptions: {
                  label: this.translocoService.translate(
                    'reservation.tabs.press.label'
                  ),
                  description: this.translocoService.translate(
                    'reservation.tabs.press.description'
                  ),
                },
                hideExpression: (model: any) => {
                  if (model.field_guest_type?.id) {
                    return model.field_guest_type?.id !== guestTypePressUuid;
                  }
                  return true;
                },
                fieldGroup: [
                  pressBlock(
                    this.translocoService,
                    guestTypePressUuid,
                    subjectsOfTheVisitForm
                  ),
                ],
              },
              {
                id: 'maisons',
                templateOptions: {
                  label: this.translocoService.translate(
                    'reservation.tabs.maisons.label'
                  ),
                  description: this.translocoService.translate(
                    'reservation.tabs.maisons.description'
                  ),
                },
                fieldGroup: [reservationPartsField(this.translocoService)],
              },
              {
                id: 'guests',
                templateOptions: {
                  label: this.translocoService.translate(
                    'reservation.tabs.guests.label'
                  ),
                  description: this.translocoService.translate(
                    'reservation.tabs.guests.description'
                  ),
                },
                fieldGroup: [reservationGuestsField(this.translocoService)],
              },
            ],
          },
        ];
        return fields;
      }
    )
  );

  constructor(
    @Inject(ROUTE_RESERVATION_ID)
    private routeReservationId$: Observable<string>,
    @Inject(GUEST_TYPE_PRESS_UUID)
    private guestTypePressUuid$: Observable<string>,
    @Inject(SUBJECTS_OF_THE_VISIT_FORM)
    private subjectsOfTheVisitForm$: Observable<{ value: string; label: string }[]>,
    @Inject(GUEST_TYPE_TRADE_UUID)
    private guestTypePrivateClientUuid$: Observable<string>,
    @Inject(GUEST_TYPE_PRIVATE_CLIENT_UUID)
    private guestTypeTradeUuid$: Observable<string>,
    private translocoService: TranslocoService,
    private readonly reservationService: ReservationService,
    private reservationFormEntitiesService: ReservationFormEntitiesService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private store: Store<any>,
    private appCursorService: AppCursorService,
    @Inject(USER_HAS_REQUESTOR_ROLE)
    public hasRequestorRole$: Observable<boolean>
  ) {
    super();

    this.set({saving: false});

    const formValue$ = this.form.valueChanges.pipe(
      tap(() => {
        this.cdr.detectChanges();
      }),
      map((formValue) => {
        return {formValue};
      })
    );

    this.connect(formValue$);

    this.hold(this.select(selectSlice(['saving'])), ({saving}) => {
      if (saving) {
        this.appCursorService.setWaitingCursor();
      } else {
        this.appCursorService.setDefaultCursor();
      }
    });

    this.hold(
      combineLatest([
        this.select(selectSlice(['formValue'])),
        this.saving$,
      ]).pipe(
        filter(([{formValue}, saving]) => !!formValue?.id && !saving),
        map(([{formValue}, saving]) => formValue),
        auditTime(250),
        isEqual(),
        skip(1),
        tap((reservationForm: ReservationForm) => {
          //update Guest Type, other forms need to get this data in real time (ie: reservation-part, bottle sold field)
          this.set({
            guestType: reservationForm.field_guest_type,
            reservationParts: reservationForm.field_reservation_parts,
          });
          //Luxon DateTime to string conversion
          const dateOnlyFields = [
            'field_new_client_date_of_last',
            'field_second_desired_date',
            'field_first_desired_date',
            'field_first_desired_end_date',
            'field_second_desired_date',
            'field_second_desired_end_date',
          ];
          Object.keys(reservationForm).forEach((key) => {
            const field: any = (reservationForm as any)[key];
            if (field instanceof DateTime) {
              const dateField: DateTime = field as DateTime;
              const isDateOnlyFields = dateOnlyFields.indexOf(key) > -1;
              const format: string = isDateOnlyFields
                ? 'yyyy-MM-dd'
                : "yyyy-MM-dd'T'HH:mm:ss'Z'";
              (reservationForm as any)[key] = dateField.toFormat(format);
            }
          });
          //Save reservation to RAM store
          this.reservationFormEntitiesService.updateEntity(
            reservationForm,
            'UPDATED'
          );
        }),
        withLatestFrom(
          this.reservationFormEntitiesService.entities$,
          this.guestTypePressUuid$,
          this.saving$
        ),
        filter(([reservationForm, entities, guestTypePressId, saving]) => {
          return !saving;
        }),
        switchMap(
          ([reservationForm, entities, guestTypePressId, saving]: [
            ReservationForm,
            {
              [id: string]: FormEntity;
            },
            string,
            boolean
          ]) => {
            this.set({saving: true});
            return this.reservationService.saveEntity(
              entities,
              reservationForm.id,
              guestTypePressId
            );
          }
        )
      ),
      (
        result:
          | { [type: string]: FormEntityType[] }
          | HttpErrorResponse
          | 'no request needed'
      ) => {
        this.set({saving: false});
        if (
          !(result instanceof HttpErrorResponse) &&
          result !== 'no request needed'
        ) {
          Object.keys(result).forEach((key) => {
            const formModels: any[] = result[key];
            if (Array.isArray(formModels)) {
              formModels.forEach((formModel) => {
                this.reservationFormEntitiesService.addEntity(
                  formModel,
                  'IN_SYNC'
                );
              });
            }
          });
        } else if (result instanceof HttpErrorResponse) {
          //TODO manage error
          console.error(
            '[RESERVATION] an error occured while saving reservation',
            {
              result,
            }
          );
          this.reload();
        }
      }
    );
  }

  reload() {
    // this.#refresh$.next(true);
    this.routeReservationId$.pipe(take(1)).subscribe((reservationId) => {
      this.store.dispatch(loadReservation({reservationId}));
    });
  }
}
