import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GetBrandGuestExperiencesPipe, GetBrandGuestExperiencesPipeModule } from "@bhe/vocabularies-data";
import { GuestExperience } from "@bhe/types";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { BheIconComponentModule } from "@bhe/ui";
import { LetModule } from "@ngrx/component";
import { MatRadioChange, MatRadioModule } from "@angular/material/radio";
import { RadioGroupCompareWithDirectiveModule } from "@bhe/utils";

@Component({
  selector: "bhe-new-guest-experiences",
  standalone: true,
  imports: [CommonModule, GetBrandGuestExperiencesPipeModule, BheIconComponentModule, LetModule, MatRadioModule, RadioGroupCompareWithDirectiveModule],
  providers: [GetBrandGuestExperiencesPipe],
  template: `
    <ng-container *ngIf="brandIdentifier">
      <ng-container *ngIf="(brandIdentifier|getBrandGuestExperiences)|async; let guestExperiences">
        <mat-radio-group
          class="guest-type-radio-group"
          (change)="onChange($event)"
        >
          <ng-template
            ngFor
            [ngForOf]="guestExperiences"
            let-guestExperience
            [ngForTrackBy]="trackBy"
          >
            <ng-container *ngIf="guestExperience.enabled">
              <mat-radio-button
                [value]="{ type: guestExperience.type, id: guestExperience.id }"
              >
                <strong [innerHTML]="guestExperience.name"></strong>
                <ng-container
                  *ngIf="guestExperience.programDescription as description"
                >
                  <div [innerHTML]="description"></div>
                </ng-container>
                <div class="b-duration">
                  <bhe-ui-bhe-icon [icon]="'duration'"></bhe-ui-bhe-icon>
                  <span [innerHTML]="guestExperience.duration"></span>
                </div>
              </mat-radio-button>
            </ng-container>
          </ng-template>
        </mat-radio-group>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./new-guest-experiences.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NewGuestExperiencesComponent {

  @Input() public brandIdentifier: ResourceIdentifier | undefined;

  @Output() public guestExperienceSelected: EventEmitter<ResourceIdentifier> = new EventEmitter();

  compareWith(o1: ResourceIdentifier, o2: ResourceIdentifier): boolean {
    return o1?.id === o2?.id;
  }

  trackBy(index: number, item: GuestExperience) {
    return item.id;
  }

  onChange(event: MatRadioChange) {
    if (event.value) {
      this.guestExperienceSelected.emit(event.value);
    }
  }
}
