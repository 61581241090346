import { TranslocoService } from '@ngneat/transloco';
import { Guest, MhProfile, ReservationForm } from '@bhe/types';
import { yesNoIdkOptions } from '@bhe/utils';
import { DateTime } from 'luxon';
import { FormlyFieldConfig } from "@ngx-formly/core";

export const daysBeforeReservation = 30;

export function bheWrapper(
  translocoService: TranslocoService,
  label: string
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate(label),
    },
  };
}

export function newClientField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    type: 'radio',
    ...getField(
      translocoService,
      'field_new_client_property',
      'reservation.form.new-client.label',
      {
        required: true,
        options: [
          {
            value: true,
            label: translocoService.translate('actions.yes'),
          },
          {
            value: false,
            label: translocoService.translate('actions.no'),
          },
        ],
      }
    ),
    wrappers: ['radioWrapper'],
  };
}

export function newClientFields(
  translocoService: TranslocoService
): FormlyFieldConfig[] {
  return [
    {
      ...getField(
        translocoService,
        'field_new_client_private_member',
        'reservation.form.new-client.private-member.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
    {
      ...getField(
        translocoService,
        'field_new_client_house_event',
        'reservation.form.new-client.house-event.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
    {
      key: 'field_new_client_which_event',
      ...getField(
        translocoService,
        'field_new_client_which_event',
        'reservation.form.new-client.which-event.label'
      ),
      type: 'input',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_house_event !== 1;
      },
    },
    {
      ...getField(
        translocoService,
        'field_new_client_champagne',
        'reservation.form.new-client.visit-champagne.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
    {
      ...getField(
        translocoService,
        'field_new_client_which_houses',
        'reservation.form.new-client.which-houses.label'
      ),
      type: 'brands',
      hideExpression: (model: any, formState, field) => {
        // https://github.com/ngx-formly/ngx-formly/issues/704
        const hidden: boolean =
          field?.parent?.model?.field_new_client_champagne !== 1;
        return hidden;
      },
      wrappers: ['radioWrapper'],
    },
    {
      ...getField(
        translocoService,
        'field_new_client_ordered_months',
        'reservation.form.new-client.ordered-brands.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
    {
      ...getField(
        translocoService,
        'field_new_client_date_of_last',
        'reservation.form.new-client.last-order-date.label'
      ),
      type: 'datepicker',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_ordered_months !== 1;
      },
    },
    {
      ...getField(
        translocoService,
        'field_new_client_quality_ordered',
        'reservation.form.new-client.quality-ordered.label'
      ),
      type: 'input',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_ordered_months !== 1;
      },
    },
    {
      ...getField(
        translocoService,
        'field_new_client_met_ambassador',
        'reservation.form.new-client.ambassador.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
    {
      key: 'field_new_client_establishment',
      ...getField(
        translocoService,
        'field_new_client_establishment',
        'reservation.form.new-client.starred-establishment.label',
        {
          options: yesNoIdkOptions(translocoService),
        }
      ),
      type: 'radio',
      hideExpression: (model: any, formState, field) => {
        // access to the main model can be through `this.model` or `formState` or `model
        return model.field_new_client_property === true;
      },
      wrappers: ['radioWrapper'],
    },
  ];
}

export function budgetBlock(translocoService: TranslocoService): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    templateOptions: {
      className: 'fields-block',
      fieldsWrapperClasses: 'd-flex gap',
      description: translocoService.translate(
        'reservation.form.budget.description'
      ),
      label: translocoService.translate(
        'reservation.form.budget.block-title'
      ),
      descriptionBottom: translocoService.translate(
        'reservation.form.budget.description-bottom'
      ),
      descriptionMore: translocoService.translate(
        'reservation.form.budget.description-more'
      ),
    },
    fieldGroup: [
      {
        key: 'field_code_budget',
        ...getField(
          translocoService,
          'field_code_budget',
          'reservation.form.budget.field-label',
          {
            required: true
          }
        ),
        type: 'input'
      }
    ],
  };
}

export function wishedDatesBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    templateOptions: {
      className: 'fields-block',
      fieldsWrapperClasses: 'desired-periods d-flex gap',
      description: translocoService.translate(
        'reservation.form.desired-periods.description'
      ),
      label: translocoService.translate(
        'reservation.form.desired-periods.block-title'
      ),
      help: translocoService.translate('help.date-range'),
    },
    fieldGroup: [
      {
        wrappers: ['bheWrapper'],
        templateOptions: {
          label: translocoService.translate(
            'reservation.form.desired-periods.first'
          ),
        },
        fieldGroup: [
          {
            wrappers: ['dateRange'],
            templateOptions: {
              fields: [
                'field_first_desired_date',
                'field_first_desired_end_date',
              ],
              minDate: DateTime.now().plus({ day: daysBeforeReservation }),
            },
            fieldGroup: [
              {
                ...getField(translocoService, 'field_first_desired_date', '', {
                  required: true,
                }),
                type: 'datepicker',
              },
              {
                ...getField(
                  translocoService,
                  'field_first_desired_end_date',
                  '',
                  {
                    required: true,
                  }
                ),
                type: 'datepicker',
              },
            ],
          },
        ],
      },
      {
        wrappers: ['bheWrapper'],
        className: 'fields-block',
        templateOptions: {
          label: translocoService.translate(
            'reservation.form.desired-periods.second'
          ),
        },
        fieldGroup: [
          {
            wrappers: ['dateRange'],
            templateOptions: {
              fields: [
                'field_second_desired_date',
                'field_second_desired_end_date',
              ],
              minDate: DateTime.now().plus({ day: daysBeforeReservation }),
            },
            fieldGroup: [
              {
                ...getField(translocoService, 'field_second_desired_date', '', {
                  required: true,
                }),
              },
              {
                ...getField(
                  translocoService,
                  'field_second_desired_end_date',
                  '',
                  {
                    required: true,
                  }
                ),
              },
            ],
          },
        ],
      },
    ],
  };
}

export function reservationDateField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    templateOptions: {
      className: 'fields-block',
      description: translocoService.translate(
        'reservation.form.reservation-date.description'
      ),
      label: translocoService.translate(
        'reservation.form.reservation-date.title'
      ),
      help: translocoService.translate('help.date-range'),
    },
    fieldGroup: [
      {
        wrappers: ['dateRange'],
        templateOptions: {
          fields: ['field_reservation_date', 'field_reservation_end_date'],
        },
        fieldGroup: [
          {
            ...getField(
              translocoService,
              'field_reservation_date',
              'Start Date'
            ),
            type: 'datepicker',
          },
          {
            ...getField(
              translocoService,
              'field_reservation_end_date',
              'End Date'
            ),
            type: 'datepicker',
          },
        ],
      },
    ],
  };
}

export function guestTypeField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate('reservation.form.guest-type.label'),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_guest_type',
          'reservation.form.guest-type.label'
        ),
        type: 'guestTypes',
        modelOptions: {
          updateOn: 'change',
        },
      },
    ],
  };
}

export function countryBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block fields',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate('reservation.form.country.label'),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_country',
          'reservation.form.country.label',
          {
            required: true,
          }
        ),
        type: 'country',
        className: 'country',
        wrappers: ['form-field'],
      },
    ],
  };
}

export function bottleSold(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block fields',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate('reservation.form.country.label'),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_country',
          'reservation.form.country.label',
          {
            required: true,
          }
        ),
        type: 'country',
        className: 'country',
        wrappers: ['form-field'],
      },
    ],
  };
}

export function visitDurationBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block fields',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate(
        'reservation.form.visit-duration.block-title'
      ),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_duration_number_of_days',
          'reservation.form.visit-duration.days'
        ),
        type: 'number',
      },
      {
        ...getField(
          translocoService,
          'field_duration_number_of_hours',
          'reservation.form.visit-duration.hours'
        ),
        type: 'number',
      },
    ],
  };
}

export function servicesRequestsBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block fields',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate(
        'reservation.form.requests.block-title'
      ),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_accommodation_requested',
          'reservation.form.requests.accomodation'
        ),
        type: 'checkbox',
      },
      {
        ...getField(
          translocoService,
          'field_restaurant_requested',
          'reservation.form.requests.restaurant'
        ),
        type: 'checkbox',
      },
      {
        ...getField(
          translocoService,
          'field_tasting_requested',
          'reservation.form.requests.tasting'
        ),
        type: 'checkbox',
      },
      {
        ...getField(
          translocoService,
          'field_vineyards_visit_requested',
          'reservation.form.requests.vineyards'
        ),
        type: 'checkbox',
      },
    ],
  };
}

export function languagesBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block fields visit-languages',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate(
        'reservation.form.languages.block-title'
      ),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_main_language',
          'reservation.form.main-language.title',
          {
            required: true,
          }
        ),
        type: 'languages',
      },
      {
        ...getField(
          translocoService,
          'field_secondary_language',
          'reservation.form.secondary-language.title'
        ),
        type: 'languages',
      },
    ],
  };
}

export function mainGuestField(
  translocoService: TranslocoService,
  isNew: boolean = false,
  defaultValue: any = null,
  templateOptions: any = {}
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block form',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate('reservation.form.main-guest.label'),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_main_guest',
          'reservation.form.main-guest.label',
          {
            isNew,
            ...templateOptions,
          }
        ),
        type: 'guestFormRef',
        defaultValue,
      },
    ],
  };
}

export function invitedByField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block form',
    templateOptions: {
      className: 'fields-block',
      label: translocoService.translate('reservation.form.invited-by.label'),
      description: translocoService.translate(
        'reservation.form.invited-by.description'
      ),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_invited_by',
          'reservation.form.invited-by.label'
        ),
        type: 'mhProfileSingle',
        hideExpression: (model: any) => {
          const hasValue = !!model.field_invited_by;
          const hide: boolean = hasValue
            ? model.field_invited_by?.type !== MhProfile.type
            : false;
          return hide;
        },
        templateOptions: {
          required: true,
        },
        defaultValue: null,
      },
      {
        ...getField(
          translocoService,
          'field_invited_by',
          'reservation.form.invited-by.label'
        ),
        type: 'guestFormRef',
        hideExpression: (model: any) => {
          const hasValue = !!model.field_invited_by;
          return hasValue ? model.field_invited_by?.type !== Guest.type : true;
        },
        defaultValue: null,
      },
    ],
  };
}

export function accompanyingPeopleField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'semi-block form',
    templateOptions: {
      className: 'fields-block',
      description: translocoService.translate(
        'reservation.form.accompanying-people.description'
      ),
      label: translocoService.translate(
        'reservation.form.accompanying-people.label'
      ),
    },
    fieldGroup: [
      {
        ...getField(
          translocoService,
          'field_mh_accompanying_people',
          'reservation.form.accompanying-people.label'
        ),
        type: 'mhProfileMulti',
        defaultValue: null,
      },
    ],
  };
}

export function newClientBlock(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    hideExpression: () => {
      /*if (this.form.value.field_guest_type?.id) {
        return (
          this.form.value.field_guest_type?.id !== guestTypePressUuid
        );
      }*/
      return false;
    },
    className: 'new-client',
    fieldGroup: [
      newClientField(translocoService),
      ...newClientFields(translocoService),
    ],
  };
}

export function pressBlock(
  translocoService: TranslocoService,
  guestTypePressUuid: string,
  subjectsOfTheVisitForm: any
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    className: 'press-infos',
    templateOptions: {
      className: 'main-block',
    },
    fieldGroup: [
      {
        wrappers: ['bheWrapper'],
        templateOptions: {
          className: 'empty-block',
        },
        fieldGroup: [
          {
            wrappers: ['bheWrapper'],
            className: 'article-infos',
            templateOptions: {
              className: 'fields-block',
              label: translocoService.translate(
                'reservation.form.press.article.block-title'
              ),
            },
            fieldGroup: [
              {
                ...getField(
                  translocoService,
                  'field_press_article_before',
                  'reservation.form.press.article.before'
                ),
                defaultValue: false,
                type: 'checkbox',
                modelOptions: {
                  updateOn: 'change',
                },
              },
              {
                ...getField(
                  translocoService,
                  'field_press_article_before_when',
                  'reservation.form.press.article.date'
                ),
                type: 'input',
              },
              {
                ...getField(
                  translocoService,
                  'field_press_article_before_which',
                  'reservation.form.press.article.name'
                ),
                type: 'input',
              },
            ],
          },
          {
            wrappers: ['bheWrapper'],
            className: 'partners',
            templateOptions: {
              className: 'fields-block',
              label: translocoService.translate(
                'reservation.form.press.partners.block-title'
              ),
            },
            fieldGroup: [
              {
                ...getField(
                  translocoService,
                  'field_press_other_media_partners',
                  'reservation.form.press.partners.has-other-partners'
                ),
                defaultValue: false,
                type: 'checkbox',
                modelOptions: {
                  updateOn: 'change',
                },
              },
            ],
          },
          {
            wrappers: ['bheWrapper'],
            className: 'subjects-visit',
            templateOptions: {
              className: 'fields-block',
              label: translocoService.translate(
                'reservation.form.subjects-of-the-visit.block-title'
              ),
            },
            fieldGroup: [
              {
                ...getField(translocoService, 'field_visit_subjects', '', {
                  multiple: true,
                  options: subjectsOfTheVisitForm,
                }),
                type: 'select',
                defaultValue: [],
              },
              {
                ...getField(
                  translocoService,
                  'field_visit_subjects_so',
                  'reservation.form.special-occasion.label'
                ),
                type: 'input',
                hideExpression: (model: any, formState, field) => {
                  // access to the main model can be through `this.model` or `formState` or `model
                  if (model.field_visit_subjects?.indexOf('vs_so') > -1) {
                    return false;
                  }
                  return true;
                },
              },
              {
                ...getField(
                  translocoService,
                  'field_visit_subjects_fpv',
                  'reservation.form.focus-vintage.label'
                ),
                type: 'input',
                hideExpression: (model: any, formState, field) => {
                  // access to the main model can be through `this.model` or `formState` or `model
                  if (model.field_visit_subjects?.indexOf('vs_fpv') > -1) {
                    return false;
                  }
                  return true;
                },
              },
            ],
          },
        ],
      },
      {
        wrappers: ['bheWrapper'],
        className: 'media-infos',
        templateOptions: {
          className: 'fields-block',
          label: translocoService.translate(
            'reservation.form.press.media.block-title'
          ),
        },
        fieldGroup: [
          {
            ...getField(
              translocoService,
              'field_press_media_title',
              'reservation.form.press.media.title'
            ),
            type: 'input',
          },
          {
            ...getField(
              translocoService,
              'field_press_next_pub_date',
              'reservation.form.press.media.publication-date'
            ),
            type: 'datepicker',
          },
          {
            ...getField(
              translocoService,
              'field_press_circulations',
              'reservation.form.press.media.circulations'
            ),
            type: 'input',
          },
          {
            ...getField(
              translocoService,
              'field_press_itw_types',
              'reservation.form.press.media.interview-types'
            ),
            type: 'input',
          },
          {
            ...getField(
              translocoService,
              'field_press_pubfreq',
              'reservation.form.press.media.publication-frequency'
            ),
            type: 'input',
          },
          {
            ...getField(
              translocoService,
              'field_press_target',
              'reservation.form.press.media.target'
            ),
            type: 'input',
          },
          {
            ...getField(
              translocoService,
              'field_press_website',
              'reservation.form.press.media.website'
            ),
            type: 'input',
          },
        ],
      },
    ],
  };
}

export function reservationPartsField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    key: 'field_reservation_parts',
    type: 'repeatMaison',
    expressionProperties: getFieldDisabledExpressionProperties(
      'field_reservation_parts'
    ),
    fieldArray: {
      fieldGroup: [
        {
          key: 'id',
          type: 'reservationPartForm',
        },
        {
          key: 'type',
          className: 'hidden',
        },
      ],
    },
  };
}

export function reservationGuestsField(
  translocoService: TranslocoService
): FormlyFieldConfig {
  return {
    wrappers: ['bheWrapper'],
    key: 'field_reservation_guests',
    type: 'repeatGuest',
    expressionProperties: getFieldDisabledExpressionProperties(
      'field_reservation_guests'
    ),
  };
}

export function getField(
  translocoService: TranslocoService,
  property: string,
  label: string,
  templateOptions: any = {},
  description: string | null = null
): FormlyFieldConfig {
  return {
    className: property,
    key: property,
    templateOptions: {
      label: translocoService.translate(label),
      attributes: {
        autocomplete: (Math.random() + 1).toString(36).substring(2),
      },
      ...templateOptions,
      description,
    },
    expressionProperties: getFieldDisabledExpressionProperties(property),
    defaultValue: null,
    modelOptions: {
      updateOn: 'change',
    },
  };
}

export function getFieldDisabledExpressionProperties(property: string) {
  return {
    'templateOptions.disabled': (
      model: ReservationForm,
      formState: any,
      field: any
    ) => {
      const fieldAccess: any = model?.meta?.drupal?.fieldAccess?.[property];
      if (fieldAccess) {
        return !fieldAccess?.edit;
      }
      return false;
    },
  };
}
